import {
  useMemo,
  useCallback,
} from 'react';
import {
  useAccount,
  useNetwork,
  useSwitchNetwork,
} from 'wagmi';
import { useWeb3Modal } from '@web3modal/react';
import { ReactComponent as Ethereum } from '../../../assets/images/currency/ETH.svg'
import { ReactComponent as MetamaskIcon } from '../../../assets/images/wallets/metamask.svg';

const Connected = () => {
  const { address } = useAccount();
  const { chain } = useNetwork();
  const { switchNetwork } = useSwitchNetwork();
  const { open } = useWeb3Modal();

  const shortAddressMobile = useMemo(() => {
    if (!address) {
      return '';
    }
    return `${address.slice(0, 2)}...${address.slice(-4)}`;
  },[address]);

  const shortAddressDesktop = useMemo(() => {
    if (!address) {
      return '';
    }
    return `${address.slice(0, 5)}...${address.slice(-4)}`;
  },[address]);

  const walletClickHandler = useCallback(() => {
    if (chain?.id !== 1) {
      switchNetwork?.(1);
    } else {
      open();
    }
  }, [chain, open, switchNetwork]);

  return (
    <div className="flex items-center cursor-pointer" onClick={walletClickHandler}>
      <div className="flex flex-col gap-1 mr-2">
        <div className="flex items-center pl-[3px] text-blue text-[14px] font-semibold tablet:hidden ">
          {/* <MetamaskIcon className="mr-1" /> */}
          <span className={`w-3 h-3 mr-1 rounded-full ${chain?.id === 1 ? 'bg-green-300' : 'bg-red-500'}`}></span>
          <img src="/wallet.png" className="mr-1" alt="X" width={15} height={15} />
          {shortAddressMobile}
        </div>
        <div className="hidden gap-1 items-center pl-[3px] text-blue text-[14px] font-semibold tablet:flex">
          {/* <MetamaskIcon className="mr-1" /> */}
          <span className={`w-3 h-3 mr-1 rounded-full ${chain?.id === 1 ? 'bg-green-300' : 'bg-red-500'}`}></span>
          <img src="/wallet.png" className="mr-1" alt="X" width={15} height={15} />
          {shortAddressDesktop}
        </div>

        {
          chain?.id === 1
            ? (
              <div className="flex items-center text-[12px] font-medium">
                <Ethereum className="mr-1 w-4 h-4" />
                Ethereum
              </div>
            )
            : (
              <div className="text-[12px] font-medium">
                Switch to ethereum
              </div>
            )
        }
      </div>
    </div>
  )
};

export default Connected;
